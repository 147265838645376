.header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header .loggedIn {
  display: flex;
  justify-content: space-between;
  margin-left: 40%;
  width: 50%;
}

.header .loggedIn .buttons {
  display: flex;
  justify-content: space-between;
  max-width: 200px;
}

button {
  width: 90px;
  height: 30px;
  background-color: whitesmoke;
  border: 1px solid #282c34;
}

.parks, .admin {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.admin .users {
  margin-right: 10%;
}

.admin .users h2 {
  margin-bottom: 0;
}

.parks-grid, .users-grid {
  display: grid;
  grid-template-columns: auto auto auto auto 250px;
}

.parks-grid-item-header, .users-grid-item-header {
  border-bottom: 1px solid black;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 5px;
}

.parks-grid-item, .users-grid-item {
  border-bottom: 1px solid black;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
}

.parks-grid-item img {
  width: 100%;
  min-width: 100px;
}


.users-grid {
  grid-template-columns: auto auto;
}

.weather {
  width: 100%;
}

.login {
  margin: 20px 20%;
}

.login-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login button {
  width: 100%;
}


@media only screen and (max-width: 600px) {
  .header .loggedIn {
    margin: 0;
    width: 100%;
    justify-content: space-around;
  }

  .parks {
    margin: 0;
  }

  .parks-grid {
    grid-template-columns: auto auto auto auto 120px;
    margin: 10px;
    width: 100vw;
  }
  
  .login {
    margin: 20px;
  }

  .parks-grid-item, .users-grid-item {
    padding: 0px;
  }
  .parks-grid-item-header { 
    padding: 0 1px;
  }
}

.login-item, .error {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 20px;
}

.error {
  justify-content: center;
  color: red;
}

.info {
  color: green;
}

.login-item label { 
  margin-right: 10px;
  flex: 0 0 100px;
}

.login-item input { 
  border: 1px solid #282c34;
  flex: 1;
}

input[type="file"] {
  display: none;
}

.budget-upload label, .budget-download a {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
}
